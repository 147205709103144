(function() {
    'use strict';

    angular.module("aerosApp")
        .controller("summaryResultsCtrl", SummaryResultsController);

    SummaryResultsController.$inject = ["$scope", "$rootScope", "aerosApi", "$state", "$stateParams", "$q", "$timeout",
        "routesConstant"];

    function SummaryResultsController($scope, $rootScope, aerosApi, $state, $stateParams, $q, $timeout,
                                      routesConstant) {
        var CUSTOMER = routesConstant.CUSTOMER;

        if (!$scope.allow['viewProject']) return $scope.reject();

        ( function init() {
            $scope.deselectProjects();
            $scope.fibergroupLabel = "Loading...";
            $scope.formData = {
                resultType: $stateParams.resultType || "All",
                fiberGroupFilter: '',
                resultIcons: {
                    "PassedOrComplete": "check",
                    "Failed": "times",
                    "Incomplete": "question"
                },
                filteredFibergroups: function () {
                    var fibergroups;

                    if (!$scope.summary) {
                        fibergroups = [];
                    } else if (!$scope.formData.fiberGroupFilter) {
                        fibergroups = $scope.summary.fiberGroups;
                    } else {
                        fibergroups = _.filter($scope.summary.fiberGroups, function (fiberGroup) {
                            return (fiberGroup.name.toLowerCase().indexOf($scope.formData.fiberGroupFilter.toLowerCase()) > -1);
                        });
                    }

                    fibergroups.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });

                    return fibergroups;
                },
                columnHasFilteredResults: function (filterProps) {
                    // I. no tests of passed-in test type exist in the full FG list: HIDE column
                    if (!filterProps.hasTests) {
                        return false;
                    } else if (!$scope.formData.fiberGroupFilter && $scope.formData.resultType === 'All') {
                        // II. tests of this type DO exist in the full FG list. . .
                        // A. . .& no filters have been applied: SHOW column
                        return true;
                    } else if (!$scope.formData.fiberGroupFilter) {
                        // II. tests of this type DO exist in the full FG list. . .
                        // B. . .& some kind of filter has been applied. . .
                        // 1. . .& FG list is NOT reduced (so the other filter, a result type, has been selected): The passed-in type has an existence check by result type
                        // (NOTE: must adjust conditions if additional filters are required in the future)
                        return filterProps['has' + $scope.formData.resultType];
                        // II. tests of this type DO exist in the full FG list. . .
                        // B. . .& some kind of filter has been applied. . .
                        // 2. . .& FG list IS reduced. . .
                    } else if ($scope.formData.resultType === 'All') {
                        // II. tests of this type DO exist in the full FG list. . .
                        // B. . .& some kind of filter has been applied. . .
                        // 2. . .& FG list IS reduced. . .
                        // a. . .& no result type has been selected - have to check reduced list for existence of ANY results of the passed-in test type
                        return _.find($scope.formData.filteredFibergroups(), function (fibergroup) {
                            return fibergroup.summaryResults[filterProps.name];
                        });
                    } else {
                        // II. tests of this type DO exist in the full FG list. . .
                        // B. . .& some kind of filter has been applied. . .
                        // 2. . .& FG list IS reduced. . .
                        // b. . .& BOTH FG reduced + result type selected - have to check reduced list for existence of selected result type, for the passed-in test type
                        return _.find($scope.formData.filteredFibergroups(), function (fibergroup) {
                            return (fibergroup.summaryResults[filterProps.name] === $scope.formData.resultType);
                        });
                    }
                },
                rowHasFilteredResults: function (fiberGroup) {
                    if ($scope.formData.resultType === 'All') {
                        return true;
                    } else {
                        return ( fiberGroup.summaryResults['has' + $scope.formData.resultType] );
                    }
                },
                showDetails: showDetails,
                showOTDRDetails: showOTDRDetails
            };

            $timeout(function () {
                loadSummaryResults($stateParams.id, true);
            }, 0);
        }() );

        function loadSummaryResults(projectId, forceReload) {
            if (forceReload || !$scope.summary) {
                $scope.fibergroupLabel = "Loading. . .";
                aerosApi.loadProjectSummary(projectId, $scope.organization.id).then(function (rtn) {
                    $scope.fibergroupLabel = "Fiber Group";
                    $scope.summary = rtn.data;
                });
            }
        }

        function showOTDRDetails(configObj) {
            configObj = configObj || {};
            configObj.testType = configObj.testType || {};
            configObj.testType.name = 'OTDR';
            showDetails(configObj);
        }

        function showDetails(configObj) {
            // reject blank cell
            if (configObj.fiberGroup && configObj.testType && !configObj.populated) {
                return;
            }
            var paramsObj = {
                id: $stateParams.id, // project id
                resultType: "all",
                linkmap: 0
                // resultType: mapRTtoCurrentDetail[$scope.formData.resultType],
            };
            // . . . decision to only pass pre-filter for resultType to detail view when clicking cell w/ failed summary result
            if (configObj.cell === "Failed") {
                paramsObj.resultType = mapRTtoCurrentDetail[configObj.cell];
            }
            if (configObj.fiberGroup) {
                paramsObj.qry = "id=" + configObj.fiberGroup.uuid;
                paramsObj.testType = "fibergroup";
            } else {
                paramsObj.qry = undefined;
            }
            if (configObj.testType) {
                paramsObj.testType = configObj.testType.name;
                $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName, paramsObj);
            } else {
                $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS_ALL.stateName, paramsObj);
            }
        }
        var mapRTtoCurrentDetail = {
            "All": "all",
            "PassedOrComplete": "complete",
            "Failed": "failed-any",
            "Incomplete": "incomplete"
        };
        $scope.showOTDRDemo = function () {
            showOTDRDetails();
        };
    }

    String.prototype.capitalize = function () {
        return this.toLowerCase().replace(/\b./g, function (a) {
            return a.toUpperCase();
        });
    };

})();


